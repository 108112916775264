import React, { useState } from "react";
import { useFormFields, useMailChimpForm } from "use-mailchimp-form";
import styles from './EmailSignUp.module.css';

function EmailSignUp() {
	const url = 'https://gmail.us21.list-manage.com/subscribe/post?u=72ccb77d15066fc95c1227286&amp;id=9338421b40&amp;f_id=001a28e7f0';
	const {
		loading,
		error,
		success,
		message,
		handleSubmit
	} = useMailChimpForm(url);

	const [ submitting, setSubmitting ] = useState(false);

	const { fields, handleFieldChange } = useFormFields({
		EMAIL: "",
		FNAME: "",
		LNAME: "",
	});

	const onSubmit = (e) => {
		e.preventDefault();
		setSubmitting(true);
		handleSubmit(fields);
		setTimeout(() => {
			setSubmitting(false);
		}, 2000)
	};

	return (
		<div className={styles.emailSignUpContainer}>
			<form
				onSubmit={onSubmit}
			>
				<div className={styles.formContainer}>
					<div className={styles.title}>
						Join our waiting list and request a demo
					</div>
					<div className={styles.hint}>
						<span className={styles.start}>{`* `}</span>
						indicates required
					</div>
					<div className={styles.field}>
						<label for="EMAIL">
							Email Address
							<span className={styles.start}>{` *`}</span>
						</label>
						<input
							id="EMAIL"
							name="email"
							required
							type="email"
							value={fields.EMAIL}
							onChange={handleFieldChange}
						/>
					</div>
					<div className={styles.field}>
						<label htmlFor="FNAME">
							First Name
							<span className={styles.start}>{` *`}</span>
						</label>
						<input
							id="FNAME"
							name="FNAME"
							type="text"
							required
							value={fields.FNAME}
							onChange={handleFieldChange}
						/>
					</div>
					<div className={styles.field}>
						<label htmlFor="LNAME">
							Last Name
						</label>
						<input
							id="LNAME"
							type="text"
							name="LNAME"
							value={fields.LNAME}
							onChange={handleFieldChange}
						/>
					</div>
					<div className={styles.submitButtonContainer}>
						<button className={styles.submitButton} disabled={submitting}>
							Subscribe
						</button>
						<div className={styles.message}>
							{ submitting && "Submitting..."}
							{ success && !submitting && message}
						</div>
					</div>
				</div>
			</form>
		</div>
	);
}

export default EmailSignUp;