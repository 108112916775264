import styles from "./App.module.css";
import EmailSignUp from "./EmailSignUp/EmailSignUp";

const AboutUsText = [
  "Welcome to Rele, the premier real estate messaging app designed to optimize the way you connect, collaborate, and close deals in the dynamic world of real estate.",
  "At Rele, we understand that in the fast-paced realm of property transactions, effective communication is the cornerstone of success. Our mission is to provide a seamless platform that empowers real estate professionals to communicate and collaborate.",
  "At Rele, we're not just building an app; we're fostering a community of dedicated real estate professionals who are passionate about their craft. We believe that every property transaction is a story waiting to be told, and we're here to provide the digital canvas on which those stories unfold.",
  "Join us on this exciting journey as we redefine the way you connect in real estate. Whether you're a seasoned industry veteran or just starting your journey, Rele is your trusted companion every step of the way.",
  "Welcome to the future of real estate communication. Welcome to Rele.",
];

const WhyReleText = [
  {
    "Instant Connectivity":
      "Connect with all the agents in the same MLS. Rele's real-time messaging ensures that you're always in touch with your colleagues and partners.",
  },
  {
    "Secure Communication":
      "Safeguard sensitive information with end-to-end encryption. Your privacy is our priority, and we provide a secure environment for all your real estate discussions.",
  },
  {
    "Effortless Collaboration":
      "Seamlessly collaborate on property listings, market insights, and documents. Share photos, videos, and important fileswith ease, enabling you to work together regardless of your location.",
  },
  {
    "Personalized Profiles":
      "Create a professional profile that showcases your expertise and listings with our digital business card.",
  },
  {
    "User-friendly Interface":
      "Our intuitive design ensures that you can focus on what matters most —— building relationships and closing deals. Say hello to a user expereince that simplifies your daily interactions.",
  },
];

function App() {
  return (
    <div className={styles.body}>
      {renderHeader()}
      {renderAboutUs()}
      {renderWhyRele()}
      <img src={require("./images/phones.png")} alt="phones" />
      <EmailSignUp />
    </div>
  );
}

const renderHeader = () => {
  return (
    <div className={styles.header}>
      {renderNavbar()} {renderTitle()}
    </div>
  );
};

const renderNavbar = () => {
  return (
    <div className={styles.header__navbar}>
      <div className={styles.header__navbar__menu}>
        <a href="/#">Home</a>
        <a href="/#aboutUs">About Us</a>
        {/*<a href="/#">Contact</a>*/}
      </div>
      <div className={styles.header__navbar__appName}>
        <img src={require("./images/Logo White with icon.png")}/>
      </div>
      <div className={styles.header__navbar__socialMedia}>
        <img
          src={require("./images/social-media.png")}
          alt="social media icons"
        />
      </div>
    </div>
  );
};

const renderTitle = () => {
  return (
    <div className={styles.header__title}>
      <span className={styles.header__title__main}>Real Estate</span>
      <span className={styles.header__title__main}>Messenger</span>
      <span className={styles.header__title__sub}>
        The only efficient communication app for high performing real estate
        professionals.
      </span>
    </div>
  );
};

const renderAboutUs = () => {
  return (
    <div className={styles.aboutUs} id="aboutUs">
      <img src={require("./images/app-store.png")} alt="app store" />
      <span>About Us</span>
      {AboutUsText.map((paragraph) => {
        return <p>{paragraph}</p>;
      })}
    </div>
  );
};

const renderWhyRele = () => {
  return (
    <div className={styles.whyRele}>
      <span className={styles.whyRele__title}>Why Choose Rele</span>
      {WhyReleText.map((item) => {
        const name = Object.keys(item)[0];
        const text = item[name];
        return (
          <div className={styles.whyRele__item}>
            <div className={styles.whyRele__item__name}>{name}</div>
            <div className={styles.whyRele__item__text}>{text}</div>
          </div>
        );
      })}
    </div>
  );
};

export default App;
